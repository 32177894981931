











import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import CourseClass from '@/store/entities/courses/courseclass';

@Component({
    components: { ModalView, FormBase },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.courseclass = Util.extend(true, {}, this.$store.state.courseclass.editModel);                     
                obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class CourseClassForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    courseclass: CourseClass = new CourseClass();
    modalSetting: any = {
        entity: "courseclass",
        title: 'Clase',
        width: "90%",
        showForm: false,
    };
    
    items = [
        this.gItem({ type: 'object', prop: 'course_id', label: 'Curso', required: true, size: 7, chips: true, onchange: this.changeCourse }),
        this.gItem({ type: 'text', prop: 'title', label: 'Titulo de la clase', size: 3, required: true }),
        this.gItem({ type: 'object', prop: 'schedule_id', label: 'Horario', required: true, filterParam: 'course_id', filterVal: [], size: 2, chips: true, onchange: this.changeSche }),
        this.gItem({ type: 'text', prop: 'theme', label: 'Tema de la clase', size: 12 }),
        //this.gItem({ type: 'object', prop: 'fee_id', label: 'Tarifa por defecto', size: 4, hint: '', persistentHint: '', required: true, chips: true, onchange: this.changeFee }),
        this.gItem({ type: 'date', prop: 'start_date', label: 'Fecha de Inicio', required: true, size: 4 }),
        this.gItem({ type: 'date', prop: 'end_date', label: 'Fecha de Finalizacion', required: true, size: 4 }),
        this.gItem({ type: 'date', prop: 'last_enroll_day', label: 'Ultimo dia de Matricula', size: 4 }),
        this.gItem({ type: 'text', prop: 'fixed_link', label: 'Link del Curso Fijo', hint: 'Si el curso tiene un link fijo se puede ingresar aqui', persistentHint: true, preicon: 'http', size: 12 }),
        this.gItem({ type: 'object', prop: 'teachers_list', label: 'Ponentes', entity: 'teacher', itemText: 'fullname', size: 12, multiple: true, chips: true }),
        this.gItem({ type: 'textarea', prop: 'teachers_text', label: 'Descripcion de Ponentes para certificados', rows: 3, hideDetails: true, class: 'textarea-dense', size: 12, hint: 'Para NEGRITA colocar las palabras entre *, por ejemplo *Dr Juan Peres*', persistentHint: true }),
        this.gItem({ type: 'divider', subheader: 'Configuracion', size: 12 }),
        this.gItem({ type: 'number', prop: 'capacity', label: 'Nro Maximo de Asistentes', required: true, size: 3, prefix: '#' }),
        this.gItem({ type: 'number', prop: 'min_score', label: 'Calificacion Minima', size: 3, prefix: '#', hint: 'Minimo Requerido para aprobar', persistentHint: true }),
        this.gItem({ type: 'number', prop: 'min_attendance', label: 'Asistencia Minima', required: true, minVal: 0, maxVal: 100, size: 3, suffix: '%', hint: 'Asistencia Minima Requerido para aprobar', persistentHint: true }),
        this.gItem({ type: 'number', prop: 'academic_hours', label: 'Horas Academicas', size: 3, required: true, suffix: 'Horas' }),
        this.gItem({ type: 'radio', prop: 'payment_type', label: 'Modalidad de Pago', size: 6, options: 'payment_types', row: true }),
        this.gItem({ type: 'number', prop: 'marks_timeout', label: 'Tiempo Para Marcar Asistencia', size: 3, preicon: 'timer', suffix: 'Minutos', hint: 'Si coloca 0 el tiempo sera manual', persistentHint: true }),
        this.gItem({ type: 'text', prop: 'prefix_code', label: 'Prefijo para Codigo correlativo', size: 3 }),
    ]

    get showEnroll() {
        return this.courseclass.id > 0 && !this.courseclass.enrolling && !this.courseclass.in_process && !this.courseclass.finished;
    }

    get isEditing() {
        return this.courseclass.id > 0;
    }

    get loading() {
        return this.$store.state.courseclass.loading;
    }

    changeCourse(id) {
        let course = Util.getEntity(this, 'course', id);
        this.setItem(this.items, 'schedule_id', 'filterVal', [id]);
        let start = (this as any).$moment(this.courseclass.start_date);
        if (this.courseclass.theme == '' || this.courseclass.theme == null)
            this.courseclass.theme = course.description;
        this.courseclass.end_date = start.add(course.duration, (course.duration_type == 'M' ? 'months' : 'days')).format('YYYY-MM-DD');
        if (this.courseclass.academic_hours == 0 || this.courseclass.academic_hours == null)
            this.courseclass.academic_hours = course.academic_hours;            
    }

    changeSche(id) {
        let data = Util.getEntity(this, 'schedule', id);
        if (data == undefined) return;
        this.courseclass.fee_id = data.fee_id;
        this.changeFee(data.fee_id);
    }

    changeFee(id) {
        let data = Util.getEntity(this, 'fee', id);
        if (data == undefined) return;
        let sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
        this.setItem(this.items, 'fee_id', 'hint', sign + ' ' + data.amount);
    }

    initForm() {
        this.setItem(this.items, 'course_id', 'readonly', false);
        this.setItem(this.items, 'schedule_id', 'readonly', false);
        this.setItem(this.items, 'schedule_id', 'readonly', false);
        this.setItem(this.items, 'start_date', 'disabled', false);
        this.setItem(this.items, 'end_date', 'disabled', false);
        this.setItem(this.items, 'last_enroll_day', 'disabled', false);
        this.setItem(this.items, 'fee_id', 'readonly', false);
        this.setItem(this.items, 'teacher_id', 'readonly', false);
        this.setItem(this.items, 'payment_type', 'disabled', false);
        this.setItem(this.items, 'teachers_list', 'disabled', false);
        this.setItem(this.items, 'title', 'readonly', false);
        this.setItem(this.items, 'fixed_link', 'readonly', false);
        this.setItem(this.items, 'capacity', 'readonly', false);
        this.setItem(this.items, 'min_score', 'readonly', false);
        this.setItem(this.items, 'min_attendance', 'readonly', false);
        this.setItem(this.items, 'marks_timeout', 'readonly', false);

        if (this.courseclass.id > 0) {
            this.changeCourse(this.courseclass.course_id);
            this.changeFee(this.courseclass.fee_id);

            if (this.courseclass.enrolling || this.courseclass.in_process || this.courseclass.finished) {
                this.setItem(this.items, 'course_id', 'readonly', true); 
                this.setItem(this.items, 'fee_id', 'readonly', true);
                //this.setItem(this.items, 'title', 'readonly', true);

                if (this.courseclass.in_process || this.courseclass.finished) {
                    this.setItem(this.items, 'schedule_id', 'readonly', true);
                    this.setItem(this.items, 'start_date', 'disabled', true);
                    this.setItem(this.items, 'end_date', 'disabled', true);
                    this.setItem(this.items, 'last_enroll_day', 'disabled', true);
                }

                if (this.courseclass.finished) {
                    this.setItem(this.items, 'teacher_id', 'readonly', true);
                    this.setItem(this.items, 'fixed_link', 'readonly', true);
                    this.setItem(this.items, 'teachers_list', 'disabled', true);
                    this.setItem(this.items, 'capacity', 'readonly', true);
                    this.setItem(this.items, 'min_score', 'readonly', true);
                    this.setItem(this.items, 'min_attendance', 'readonly', true);
                    this.setItem(this.items, 'marks_timeout', 'readonly', true);
                }
            }

            if (this.courseclass.in_process || this.courseclass.finished) {
                this.setItem(this.items, 'payment_type', 'disabled', true);
            }
        }
    }
    
    async save(onlySave = false) {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.courseclass);
            delete model.course;
            delete model.fee;
            delete model.schedule;
            delete model.teacher;

            let newModel = await this.$store.dispatch({
                type: 'courseclass/' + (this.courseclass.id > 0 ? 'update' : 'create'),
                data: model
            });

            if (onlySave)
                return newModel;
            else {
                this.$emit('save-success');

                this.cancel();
                if (this.courseclass.id > 0)
                    Util.abp.notify.success(this, 'Informacion del Curso actualizada!');
                else
                    Util.abp.notify.success(this, 'El Curso ha sido creado!');
            }
        }
    }

    async startEnroll() {
        let model = this.courseclass;
        if (!this.isEditing) {
            model = await this.save(true);
            Util.abp.notify.success(this, 'El Curso ha sido creado!');
        }

        await this.$store.dispatch({
            type: 'courseclass/startEnroll',
            data: { id: model.id}
        });
        this.$emit('save-success');
        this.cancel();
        this.$swal(this.L('Matriculas Iniciadas'), 'El proceso de matriculas ha iniciado, los asistentes ya pueden registrarse!', 'success');
    }

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {
    }

    created() {
    }
}
