





















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import CourseClassForm from './class-form.vue';
import History from '@/views/_shared/history.vue';

class PageCourseClassRequest extends PageRequest {
    course: number = null;
    schedule: number = null;
    state: number = null;
    date_from: string = new Date().toISOString().substr(0, 4) + '-01-01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ListView, FormBase, CourseClassForm, History }

})
export default class CourseClasses extends ListBase {
    entity: string = 'courseclass';
    pagerequest: PageCourseClassRequest = new PageCourseClassRequest();
    filterForm: boolean = true;
    hisModalShow: boolean = false;
    viewShow: boolean = false;
    sState: any = Util.abp.list.getCodeList('E_CLASS');

    filterItems = [
        this.gItem({ type: 'object', prop: 'course', label: 'Curso', size: 4, hideDetails: true, onchange: this.changeCourse }),
        this.gItem({ type: 'object', prop: 'schedule', label: 'Horario', filterParam: 'course_id', filterVal: [], size: 2, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', size: 2, generalType: 'E_CLASS', hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_from', label: 'Fechas Desde', size: 2, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.afterSave }),
    ]

    columns = [
        this.gCol('name', 'Curso', 'text'),
        //this.gCol('title', 'Titulo', 'text'),
        this.gCol('schedule.name', 'Horario', 'text'),
        this.gCol('teacher.fullname', 'Ponente', 'text'),
        this.gCol('certified', 'Certificado', 'boolean','',false), 
        this.gColO({ type: 'chip', value: 'state_id', text: 'Estado', color: 'stateColor', generalType: true, onclick: this.showHistory }),
        this.defaultActions('view|edit|delete', 130)
    ]

    get exluddeIds() {
        let ids = [];
        let ids2 = [];
        let ids3 = [];
        this.$store.state.courseclass.list.forEach(item => {
            if (item.state_id == this.sState.RE)
                ids.push(item.id);
            if (item.enrolled_students > 0 || item.certified || item.state_id == this.sState.CE)
                ids2.push(item.id);
            if (item.state_id == this.sState.CE)
                ids3.push(item.id);
        });

        return { view: ids, delete: ids2, edit: ids3 };
    }

    changeCourse(id) {
        this.setItem(this.filterItems, 'schedule', 'filterVal', [id]);
        this.afterSave();
    }

    showHistory(item) {
        this.$store.commit('courseclass/view', item);
        this.hisModalShow = !this.hisModalShow;
    }        

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    view() {
        let item = this.$store.state.courseclass.editModel;
        this.$router.push({ name: 'classview', params: { id: item.id } });
    } 

    async afterSave() {
        let list: any = this.$refs.listView
        if (list != undefined)
            await list.getpage();
    }

                         
    created() {
    }

    mounted() {
    }
}